import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Hassan Sohail</span> from{" "}
            <span className="purple">London, Ontario.</span>
            <br />
            I am currently a software developer, working on my own platform called Nexgeius.
            <br />
            I am pursuing a Bachelor's in Software Engineering at Western University.
            <br />
            <br />
            Besides my passion for coding, here are a few activities I enjoy:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Exploring new technology trends
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading books on personal development
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling and experiencing different cultures
            </li>
          </ul>
          <p style={{ color: "rgb(155 126 172)", marginTop: "20px" }}>
            "Strive to build impactful and innovative solutions!"
          </p>
          <footer className="blockquote-footer">Hassan Sohail</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
