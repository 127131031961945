import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import myImg from "../../Assets/IMG_4040-removebg-preview.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function Home2() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Web3Forms API endpoint
    const url = "https://api.web3forms.com/submit";
    
    // Add your Web3Forms Public Key
    const publicKey = "c74582d2-c70f-40a1-afb2-43c5d2bc07d2";

    // Prepare form data
    const data = {
      access_key: publicKey,
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    // Send data to Web3Forms
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert("Your message has been sent!");
        setFormData({ name: "", email: "", message: "" });
        setShowModal(false); // Close the modal after submission
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I’ve always had a deep passion for programming, and I’ve become proficient in various languages, including:
              <i>
                <b className="purple"> Java, Python, JavaScript, C++, HTML, and PHP.</b>
              </i>
              <br />
              <br />
              My current focus is on developing <b className="purple">Web Technologies</b> and <i><b className="purple">Products</b></i>. I’m particularly excited about my latest project, <b className="purple">Nexgeius</b>.
              <br />
              <br />
              <b>Nexgeius</b> is a full-stack platform designed to help businesses and individuals with their digital transformation needs. We offer services ranging from custom web development to app design, providing end-to-end digital solutions for clients.
              <br />
              <br />
              Recently, I’ve been working on an innovative feature for Nexgeius—a powerful <b className="purple">AI-driven tool</b> that autonomously creates websites for clients. This AI feature allows users to quickly generate a fully functioning website based on their inputs, without requiring any technical expertise. It integrates smart algorithms and <b className="purple">React</b> and <b className="purple">Firebase</b> to ensure a smooth and personalized user experience.
              <br />
              <br />
              I continue to work on optimizing this AI feature, ensuring that clients have the flexibility to edit and fine-tune their websites, while still benefiting from an automated system.
              <br />
              <br />
              Beyond that, I love working with modern technologies like <b className="purple">Node.js</b> and <i><b className="purple">React.js</b></i> and <i><b className="purple">Next.js</b></i>, always exploring new ways to innovate and build impactful products.
              </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        {/* Social Media Links */}
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONNECT WITH ME</h1>
            <p>
              Feel free to <span className="purple">connect</span> on these platforms:
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/hsohail7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/hassansohail03/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>

            {/* Adjusted Button Placement */}
            <div className="text-center mt-4">
              <Button variant="primary" onClick={() => setShowModal(true)} className="contact-button">
                Contact Me
              </Button>
            </div>
          </Col>
        </Row>

        {/* Modal for Contact Form */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Contact Me</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Write your message here"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3 w-100">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </Container>
  );
}

export default Home2;
