import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Image paths for your projects
import carGame from "../../Assets/Projects/2.jpg.png";
import ionThruster from "../../Assets/Projects/ion3.jpg";
import chessGame from "../../Assets/Projects/chess7.jpg";
import webScraper from "../../Assets/Projects/webscraper.jpg";
import dailyReminder from "../../Assets/Projects/dailyreminder.jpg";
import erpSoftware from "../../Assets/Projects/erp1.jpg";
import songVisualizer from "../../Assets/Projects/visualizer.jpg.png";
import calculator from "../../Assets/Projects/calc.jpg.png";
import guardians from "../../Assets/guardians.png"; // Placeholder for Guardians of the Forgotten Lands
import nexgeius from "../../Assets/Projects/nextgenius.jpg"; // Placeholder for Nexgeius project
import personalPortfolio from "../../Assets/Projects/portfolio.jpg"; // Placeholder for Personal Portfolio
import unescoHackathon from "../../Assets/Projects/hackathon.jpg"; // Placeholder for UNESCO Hackathon

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          {/* Nexgeius - Website */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nexgeius}
              isBlog={false}
              title="Nexgeius - Website"
              description="A full-stack platform designed to help businesses and individuals with digital transformation, offering custom web development and app design services."
              demoLink="https://nexgeius.com" // Add actual demo link
            />
          </Col>

          {/* Guardians of the Forgotten Lands */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={guardians}
              isBlog={false}
              title="Guardians of the Forgotten Lands"
              description="A 2D RPG developed in Unity as part of a university project. Focused on designing the combat mechanics, player health system, and AI behavior for early encounters. The game includes quests, leveling systems, and a dynamic storyline."
            />
          </Col>

          {/* UNESCO Hackathon */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={unescoHackathon}
              isBlog={false}
              title="UNESCO Hackathon"
              description="A collaborative project built for the UNESCO Hackathon, focusing on sustainable development solutions through innovative digital tools."
              ghLink="https://github.com/hsohail7/World-Engineering-Day-Hackathon" // Add actual GitHub link
              demoLink="https://hsohail7.github.io/World-Engineering-Day-Hackathon/" // Add actual demo link
            />
          </Col>

          {/* ERP Software (SK Solution) */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={erpSoftware}
              isBlog={false}
              title="ERP Software (SK Solution)"
              description="Developed for SK Solutions, this ERP software is a powerful tool for managing customer relationships, including lead tracking and task management."
              demoLink="https://www.sksolution.ca/" // Add actual demo link
            />
          </Col>

          {/* Personal Portfolio Website */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={personalPortfolio}
              isBlog={false}
              title="Personal Portfolio Website"
              description="A responsive portfolio website showcasing my projects, skills, and accomplishments in web development and software engineering."
              demoLink="https://hassansohail.ca/" // Add actual demo link
            />
          </Col>

          {/* Song Visualizer */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={songVisualizer}
              isBlog={false}
              title="Song Visualizer"
              description="A fascinating project that visualizes musical volume data using Processing in Java, transforming audio into mesmerizing visual patterns."
            />
          </Col>

          {/* Car Game (Unreal Engine 4) */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={carGame}
              isBlog={false}
              title="Car Game (Unreal Engine 4)"
              description="An immersive car racing game developed using Unreal Engine 4 as a final exam project. The game features stunning graphics, lifelike physics, and challenging tracks."
            />
          </Col>

          {/* Ion Thruster */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ionThruster}
              isBlog={false}
              title="Ion Thruster"
              description="This project delves into ion propulsion technology, designed with a vision of powering a future flying car experiment, reflecting a passion for innovation."
            />
          </Col>

          {/* Chess Game (Java) */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chessGame}
              isBlog={false}
              title="Chess Game (Java)"
              description="A Java-based Chess Game created during the pandemic, implementing unique paths for each chess piece to explore chess strategies."
            />
          </Col>

          {/* Web Scraping Tool */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webScraper}
              isBlog={false}
              title="Web Scraping Tool"
              description="A tool developed using Python's Beautiful Soup library for web scraping, combined with a versatile calculator. It represents an exploration of data extraction."
            />
          </Col>

          {/* Daily Reminder Tool */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dailyReminder}
              isBlog={false}
              title="Daily Reminder Tool"
              description="A handy application for setting and managing daily tasks, appointments, and reminders to help users stay organized and never miss important events."
            />
          </Col>

          {/* Calculator GUI (Java) */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={calculator}
              isBlog={false}
              title="Calculator GUI (Java)"
              description="A Java-based graphical user interface calculator developed during the pandemic. This project showcases an interest in GUI-based software development."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
